<template>
    <widget-container class="widget-iframe-container" name="Map" v-if="widget" :widget="widget">
        <template v-if="widget.loaded">
            <b-img class="map-image" :src="mapSrc"/>
        </template>
        <template v-else>
            <div class="map-placeholder">
                <div v-if="!error">
                    Loading map...
                </div>
                <div v-else style="color: darkred;">
                    Error loading map...
                </div>
            </div>
        </template>
    </widget-container>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import {Widget} from "../helpers/Widget.js";

export default {
    name: `widget-map`,
    data() {
        return {
            mapSrc: null,
            widget: null,
            error: false
        }
    },
    async mounted() {
        this.widget = new Widget(`map`, this.$route.params.id);
        await this.widget.load();
        this.loadMap();
    },
    methods: {
        loadMap() {
            if (this.widget.config.disable_cache) {
                delete this.widget.config.disable_cache;
            }

            Network.post(`/api/maps/static`, this.widget.config, {
                responseType: `blob`,
                contentType: `application/x-www-form-urlencoded`
            })
                .then(resp => {
                    console.log(resp.data)
                    this.mapSrc = window.URL.createObjectURL(resp.data)
                })
                .catch(e => {
                    console.error(e);
                    this.error = true;
                });
        }
    }
}
</script>

<style lang="scss" scoped>

.map-image,
.map-placeholder {
    width: 100%;
    height: 100%;
}

.map-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    color: #bbb;
}
</style>
